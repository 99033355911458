import React from "react"
import SEO from "../components/seo"
import ToolsImg from "./../images/tools.svg"
import "./../style/tools.scss"

const Tools = () => (
  <>
    <SEO title="Tools" />
    <ToolsImg className="tools-img" />
  </>
)

export default Tools
